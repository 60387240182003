var render = function render(){var _vm=this,_c=_vm._self._c;return _c('SuawMainContent',[(_vm.isLoading)?_c('div',{staticClass:"loading-container"},[_vm._v(" Loading... ")]):_c('div',[(_vm.eventProblems.length > 0)?_c('div',[_c('SuawButton',{attrs:{"button-text":"Export CSV","type":"primary","size":"small"},on:{"click":_vm.exportToCsv}}),_c('table',{staticClass:"event-problems-table"},[_c('thead',[_c('tr',[_c('th',[_vm._v("Date Reported")]),_c('th',[_vm._v("Event Date")]),_c('th',[_vm._v("Event Title")]),_c('th',[_vm._v("Host Name")]),_c('th',[_vm._v("Host Email")]),_c('th',[_vm._v("User Name")]),_c('th',[_vm._v("User Email")]),_c('th',[_vm._v("Problem Type")]),_c('th',[_vm._v("Comment")])])]),_c('tbody',_vm._l((_vm.eventProblems),function(problem){return _c('tr',{key:problem.id},[_c('td',[_vm._v(_vm._s(_vm.formatDate(problem.created_at)))]),_c('td',[_vm._v(_vm._s(_vm.formatDate(problem.event_occurrence.starts_at)))]),_c('td',[_c('router-link',{attrs:{"to":{
                  name: 'Series',
                  params: {
                    chapterSlug: problem.event_occurrence.event_series.chapter.slug,
                    seriesSlug: problem.event_occurrence.event_series.slug,
                    eventId: problem.event_occurrence.id
                  }
                },"target":"_blank","rel":"noopener noreferrer"}},[_vm._v(" "+_vm._s(_vm.getEventTitle(problem))+" ")])],1),_c('td',[_c('router-link',{attrs:{"to":{
                  name: 'UserView',
                  params: {
                    userId: problem.event_occurrence.event_series.organizers[0].user_view.id
                  }
                },"target":"_blank","rel":"noopener noreferrer"}},[_vm._v(" "+_vm._s(_vm.getHostName(problem))+" ")])],1),_c('td',[_vm._v(_vm._s(_vm.getHostEmail(problem)))]),_c('td',[_c('router-link',{attrs:{"to":{
                  name: 'UserView',
                  params: {
                    userId: problem.user_id
                  }
                },"target":"_blank","rel":"noopener noreferrer"}},[_vm._v(" "+_vm._s(_vm.getUserName(problem))+" ")])],1),_c('td',[_vm._v(_vm._s(_vm.getUserEmail(problem)))]),_c('td',[_vm._v(_vm._s(_vm.formatProblemType(problem.problem_type)))]),_c('td',[_vm._v(_vm._s(problem.comment))])])}),0)])],1):_c('SuawEmptyState',{attrs:{"title":"No Event Problems","description":"There are no reported event problems at this time."}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }