<script>
import { SuawMainContent, SuawEmptyState, SuawButton } from "@/components";
import { GetEventProblems } from "./seriesManageOperations.gql";

export default {
  name: "EventProblems",
  components: {
    SuawMainContent,
    SuawEmptyState,
    SuawButton
  },
  data() {
    return {
      isLoading: true,
      resultEventProblems: []
    };
  },
  apollo: {
    resultEventProblems: {
      query: GetEventProblems,
      result() {
        this.isLoading = false;
      }
    }
  },
  computed: {
    eventProblems() {
      return this.resultEventProblems || [];
    }
  },
  methods: {
    getUserName(problem) {
      return problem.user.first_name + " " + problem.user.last_name;
    },
    getUserEmail(problem) {
      return problem.user.email;
    },
    getHostName(problem) {
      return problem.event_occurrence.event_series.organizers[0].user_view.first_name + " " + problem.event_occurrence.event_series.organizers[0].user_view.last_name;
    },
    getHostEmail(problem) {
      return problem.event_occurrence.event_series.organizers[0].user_view.email;
    },
    getEventTitle(problem) {
      return problem.event_occurrence.event_series.title;
    },
    getEventLink(problem) {
      const route = this.$router.resolve({
        name: "Series",
        params: {
          chapterSlug: problem.event_occurrence.event_series.chapter.slug,
          seriesSlug: problem.event_occurrence.event_series.slug,
          eventId: problem.event_occurrence.id
        }
      });
      return `${window.location.origin}${route.href}`;
    },
    getHostLink(problem) {
      const route = this.$router.resolve({
        name: "UserView",
        params: {
          userId: problem.event_occurrence.event_series.organizers[0].user_view.id
        }
      });
      return `${window.location.origin}${route.href}`;
    },
    getUserLink(problem) {
      const route = this.$router.resolve({
        name: "UserView",
        params: {
          userId: problem.user_id
        }
      });
      return `${window.location.origin}${route.href}`;
    },
    formatDate(dateString) {
      const date = new Date(dateString);
      return date.toLocaleString();
    },
    formatProblemType(type) {
      switch (type) {
        case "NoStart":
          return "Host never started";
        case "BrokenLink":
          return "Broken/incorrect link";
        case "TechnicalDifficulty":
          return "Technical difficulties";
        case "Other":
          return "Other";
        default:
          return type;
      }
    },
    exportToCsv() {
      const headers = [
        "Date Reported",
        "Event Date",
        "Event Title",
        "Host Name",
        "Host Email",
        "User Name",
        "User Email",
        "Problem Type",
        "Comment",
        "Event Link",
        "Host Link",
        "User Link"
      ];
      const data = this.eventProblems.map(problem => [
        this.formatDate(problem.created_at),
        this.formatDate(problem.event_occurrence.starts_at),
        this.getEventTitle(problem),
        this.getHostName(problem),
        this.getHostEmail(problem),
        this.getUserName(problem),
        this.getUserEmail(problem),
        this.formatProblemType(problem.problem_type),
        problem.comment,
        this.getEventLink(problem),
        this.getHostLink(problem),
        this.getUserLink(problem)
      ]);

      const csvContent = [headers.map(header => `"${header}"`).join(","), ...data.map(row => row.map(cell => `"${cell.replace(/"/g, '""')}"`).join(","))].join("\n");

      const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.setAttribute("download", "event_problems.csv");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
};
</script>

<template>
  <SuawMainContent>
    <div v-if="isLoading" class="loading-container">
      Loading...
    </div>
    <div v-else>
      <div v-if="eventProblems.length > 0">
        <SuawButton button-text="Export CSV" type="primary" size="small" @click="exportToCsv" />
        <table class="event-problems-table">
          <thead>
            <tr>
              <th>Date Reported</th>
              <th>Event Date</th>
              <th>Event Title</th>
              <th>Host Name</th>
              <th>Host Email</th>
              <th>User Name</th>
              <th>User Email</th>
              <th>Problem Type</th>
              <th>Comment</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="problem in eventProblems" :key="problem.id">
              <td>{{ formatDate(problem.created_at) }}</td>
              <td>{{ formatDate(problem.event_occurrence.starts_at) }}</td>
              <td>
                <router-link
                  :to="{
                    name: 'Series',
                    params: {
                      chapterSlug: problem.event_occurrence.event_series.chapter.slug,
                      seriesSlug: problem.event_occurrence.event_series.slug,
                      eventId: problem.event_occurrence.id
                    }
                  }"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {{ getEventTitle(problem) }}
                </router-link>
              </td>
              <td>
                <router-link
                  :to="{
                    name: 'UserView',
                    params: {
                      userId: problem.event_occurrence.event_series.organizers[0].user_view.id
                    }
                  }"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {{ getHostName(problem) }}
                </router-link>
              </td>
              <td>{{ getHostEmail(problem) }}</td>
              <td>
                <router-link
                  :to="{
                    name: 'UserView',
                    params: {
                      userId: problem.user_id
                    }
                  }"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {{ getUserName(problem) }}
                </router-link>
              </td>
              <td>{{ getUserEmail(problem) }}</td>
              <td>{{ formatProblemType(problem.problem_type) }}</td>
              <td>{{ problem.comment }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <SuawEmptyState v-else title="No Event Problems" description="There are no reported event problems at this time." />
    </div>
  </SuawMainContent>
</template>

<style lang="scss" scoped>
.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 200px;
}

.event-problems-table {
  width: 100%;
  border-collapse: collapse;

  th,
  td {
    padding: 12px 16px;
    text-align: left;
    border-bottom: 1px solid var(--sem-color-border);
  }

  th {
    font-weight: bold;
    background-color: var(--sem-color-surface);
  }

  td {
    vertical-align: top;
  }
}
</style>
